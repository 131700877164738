<template>
  <div>
    <banner>
      <template slot="header">
        {{ $t("headline") }}
      </template>
      <template slot="header-caption">
        {{ $t("subheadline") }}
      </template>
    </banner>
    <hr class="yellow-rule" />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12">
          <v-btn
            color="secondary"
            class="mr-4"
            :to="rolePath({ slug: 'product-manager' })"
            outlined
          >
            {{ $t("product_manager") }}
            <v-icon right dark>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("featured_companies") }}
          </h1>
        </v-col>
        <v-col
          v-for="company in featuredCompanies"
          :key="company.slug"
          cols="12"
          sm="3"
        >
          <v-card class="company-card" :to="companyPath(company)">
            <v-card-title
              class="text-subtitle-1 secondary--text font-weight-bold"
            >
              {{ company.name }}
            </v-card-title>
            <v-card-subtitle>
              <div class="d-block">
                <span class="mr-3">{{ $t("median") }}</span>
                <span class="text-subtitle-1 font-weight-bold primary--text">{{
                  displayCompensationItem(company.median)
                }}</span>
              </div>
              <div class="d-block">
                <span class="mr-3">{{ $t("compensation_entries") }}</span>
                <span class="font-weight-bold primary--text">{{
                  company.entries
                }}</span>
              </div>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mr-0" justify="end">
        <router-link
          :to="{ path: locale.base + '/companies' }"
          class="text-subtitle-2"
        >
          {{ $t("see_companies") }}
          <v-icon class="ml-0" x-small color="secondary" right>
            mdi-arrow-right
          </v-icon>
        </router-link>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("explore_salaries") }}
          </h1>
          <h2 class="text-subtitle-1 primary--text">
            {{ $t("explore_salaries_explanation") }}
            <router-link
              :to="{ path: locale.base + '/explore-salaries' }"
              class="text-subtitle-2 companies"
            >
              <i18n path="see_all_salaries">
                <template v-slot:salary_count>
                  <span>{{ salariesCount }}</span>
                </template>
              </i18n>
              <v-icon class="ml-0" x-small color="secondary" right>
                mdi-arrow-right
              </v-icon>
            </router-link>
          </h2>
        </v-col>
      </v-row>
      <!-- Componentified Salary table -->
      <v-row>
        <v-col col="12">
          <salary-table
            hide-default-footer
            :options="{ itemsPerPage: 20 }"
            :salaries="salaries"
            :loading="loading"
          />
        </v-col>
      </v-row>
      <!-- Link leading to expolore salaries view -->
      <v-row class="mr-0" justify="end">
        <router-link
          :to="{ path: locale.base + '/explore-salaries' }"
          class="text-subtitle-2 companies"
        >
          <i18n path="see_all_salaries">
            <template v-slot:salary_count>
              <span>{{ salariesCount }}</span>
            </template>
          </i18n>
          <v-icon class="ml-0" x-small color="secondary" right>
            mdi-arrow-right
          </v-icon>
        </router-link>
      </v-row>
      <!-- Heading for year of experience graph table -->
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 font-weight-bold primary--text">
            {{ $t("view_by_yoe") }}
          </h1>
        </v-col>
      </v-row>
      <!-- Data table for year of experience -->
      <v-row v-if="salariesGroupedByYear">
        <v-col cols="12">
          <v-data-table
            :headers="yearsOfExperienceHeaders"
            :items="salariesGroupedByYear"
            :loading="loading"
            :options="{ itemsPerPage: 6 }"
            hide-default-footer
          >
            <template v-slot:item.yearsOfExperience="{ item }">
              <div>
                <div class="text-h6 font-weight-bold">
                  {{ item.yearsOfExperience }}
                </div>
              </div>
            </template>
            <template v-slot:item.salaries="{ item }">
              <horizontal-scatter-chart
                :height="$vuetify.breakpoint.mobile ? 45 : 15"
                :width="$vuetify.breakpoint.mobile ? 260 : 100"
                :min="1500000"
                :max="30000000"
                :dataset="item.salaries"
                :locale="locale.code"
              ></horizontal-scatter-chart>
            </template>
            <template v-slot:item.firstQuartile="{ item }">
              {{ displayCompensationItem(item.firstQuartile) }}
            </template>
            <template v-slot:item.median="{ item }">
              <div class="font-weight-bold">
                {{ displayCompensationItem(item.median) }}
              </div>
            </template>
            <template v-slot:item.thirdQuartile="{ item }">
              {{ displayCompensationItem(item.thirdQuartile) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Link taking to Years of Experience Overall View -->
      <v-row class="mr-0 mb-8" justify="end">
        <router-link
          :to="{ path: locale.base + '/years-of-experience' }"
          class="text-subtitle-2 companies"
        >
          {{ $t("see_all_years") }}
          <v-icon class="ml-0" x-small color="secondary" right>
            mdi-arrow-right
          </v-icon>
        </router-link>
      </v-row>

      <v-row class="mb-4" justify="center">
        <h2>{{ $t("call_to_add") }}</h2>
      </v-row>
      <v-row justify="center" class="mb-8">
        <v-btn
          color="secondary"
          :to="{ path: 'submit-compensation-selection' }"
          rounded
          large
          dark
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
      </v-row>
      <v-row>
        <v-sheet
          class="mx-auto"
          max-width="600"
          outlined
          color="grey lighten-3"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                <v-icon large color="grey">
                  mdi-email
                </v-icon>
                {{ $t("subscribe") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("subscribe_subtext") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-col>
              <v-row>
                <v-text-field
                  v-model="email"
                  placeholder="iamdev@gmail.com"
                  class="mr-1"
                  type="email"
                  outlined
                  clearable
                  dense
                ></v-text-field>
                <v-btn
                  height="40"
                  dark
                  color="primary"
                  depressed
                  @click="createLead"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-list-item>
        </v-sheet>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="accent"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<i18n>
{
  "en": {
    "headline": "Software Engineer Salaries in Japan",
    "subheadline": "Empowering software engineers to make informed career decisions.",
    "call_to_add": "Help others like you, add your own compensation!",
    "add_compensation": "Add your compensation",
    "submit_compensation": "Submit Compensation",
    "product_manager": "Product Manager",
    "subscribe": "Subscribe",
    "subscribe_subtext": "Industry insights, career tips and site updates.",
    "submit": "submit",
    "subscribe_success_message": "Got it! We'll keep you informed.",
    "search_entries": "Search by company, role, focus, level, etc",
    "see_companies": "See all companies",
    "see_all_years": "See all years of experience",
    "see_all_salaries": "View all {salary_count} salaries",
    "company_name": "Company",
    "company_name_example": "Google, LINE, etc",
    "explore_salaries": "Explore Salaries",
    "explore_salaries_explanation": "Filter, sort and view our database of salary data with high level statistics as well as individual entries in detail.",
    "featured_companies": "Popular Companies",
    "view_by_yoe": "Compensation by Years of Experience",
    "input_date": "Date",
    "location": "Location",
    "grade": "Grade/Level",
    "role": "Role",
    "focus": "Focus",
    "years_at_company": "Years at Company",
    "years_of_experience": "Years of Experience",
    "verified_dialog_title": "This salary was verified with official documentation so you can be confident it is accurate",
    "verified_dialog": "Contributors have the option of sending in verification documents when they submit their information. Accepted documents include offer letters, change of compensation documents, payslips or tax withholding forms. {0}.",
    "verified": "Read more about verified salaries",
    "contribute_salaries": "Want to contribute, add yours!",
    "annual_compensation": "Total Compensation",
    "annual_base_salary": "Base Salary",
    "annual_bonus": "Bonus",
    "annual_stock": "Equity",
    "compensation_entries": "Entries",
    "range": "Range",
    "count": "# of Entries",
    "median": "Median"
  },
  "ja": {
    "headline": "Software Engineer Salaries in Japan",
    "subheadline": "ソフトウェアエンジニア用のクラウドソース年収データベース",
    "add_compensation": "年収を追加",
    "submit_compensation": "年収を提出",
    "product_manager": "プロダクトマネージャー",
    "subscribe": "メルマガ登録",
    "subscribe_subtext": "業界の考察、キャリアのヒント、サイトの新機能紹介など",
    "subscribe_success_message": "お知らせを配信します！",
    "submit": "登録",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "search_entries": "社名・ロール・フォカースで検索する",
    "see_companies": "すべての会社を見る",
    "see_all_years": "経験年数を見る",
    "see_all_salaries": "{salary_count}件全てを見る",
    "company_name": "企業名",
    "company_name_example": "Google、メルカリなど",
    "explore_salaries": "給与検索",
    "explore_salaries_explanation": "統計された給与情報や詳細な個々の給与情報をフィルター、並び替えして見る。",
    "featured_companies": "よく見られている企業",
    "view_by_yoe": "経験年数で見る",
    "input_date": "追加日",
    "location": "所在地",
    "grade": "グレード・レベル",
    "role": "職種",
    "focus": "フォーカス",
    "years_at_company": "勤続年数",
    "years_of_experience": "経験年数",
    "verified_dialog_title": "この給与は、正式な書類によって証明・認証済みのため、正確なデータであることが証明されています",
    "verified_dialog": "データをご提供頂ける場合、データの提出時に正式な書類を提出することが可能です。受領可能な種類は、オファーレーター、給与書類、給与明細票、源泉徴収票です。詳細は、 {0}を参照ください。",
    "verified": "認証済みの給与",
    "contribute_salaries": "あなたの情報も提供してエンジニアのパワーアップに貢献しましょう!",
    "annual_compensation": "年収",
    "annual_base_salary": "基本給(年)",
    "annual_bonus": "賞与(年)",
    "annual_stock": "株式報酬(年)",
    "compensation_entries": "件",
    "median": "中央値",
    "range": "年収範囲",
    "count": "件"
  }
}
</i18n>

<script>
import _ from "lodash";
import Banner from "components/Banner";
import api from "services/api";
import store from "store";
import HorizontalScatterChart from "components/HorizontalScatter.chart.vue";
import SalaryTable from "components/salary_table/SalaryTable";
import statistics from "../utils/statistics";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  components: {
    Banner,
    HorizontalScatterChart,
    SalaryTable
  },
  data: () => ({
    companies: [],
    featuredCompanies: [],
    salariesGroupedByYear: [],
    salaries: [],
    salariesCount: null,
    search: {},
    roles: ["Software Engineer", "Engineering Manager"],
    roleFocusList: [
      "iOS",
      "Android",
      "Mobile",
      "Web Frontend",
      "Fullstack",
      "Backend",
      "AI/ML",
      "Site Reliability(SRE)",
      "Security",
      "Devops",
      "Data",
      "Networking"
    ],
    pagination: {
      current_page: 1
    },
    link: true,
    loading: false,
    email: "",
    snackbar: false,
    text: ""
  }),
  created() {
    this.getSalaries();
    this.getFeaturedCompanies();
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    lead() {
      return {
        email: this.email
      };
    },
    yearsOfExperienceHeaders() {
      return [
        {
          text: this.$t("years_of_experience"),
          value: "yearsOfExperience"
        },
        {
          text: this.$t("range"),
          value: "salaries",
          sortable: false
        },
        {
          text: this.$t("25%"),
          value: "firstQuartile"
        },
        {
          text: this.$t("median"),
          value: "median"
        },
        {
          text: this.$t("75%"),
          value: "thirdQuartile"
        },
        {
          text: this.$t("count"),
          value: "count"
        }
      ];
    }
  },
  methods: {
    // TODO: delete this once component takes over the task of fetching salary data
    getSalaries(page = 1) {
      this.loading = "grey";
      const page_query = { page: page };
      const query = {
        ...page_query,
        exclude_job_roles: ["product-manager"]
      };
      api.getSalaries(query).then(
        resp => {
          this.salaries = resp.data.items;
          this.salariesCount = resp.data.items.length;
          this.pagination = resp.data.meta;
          this.loading = false;
          this.salariesGroupedByYear = this.groupSalaries(this.salaries);
        },
        () => {
          this.loading = false;
        }
      );
    },
    createLead() {
      api.createLead(this.lead).then(
        () => {
          this.text = this.$t("subscribe_success_message");
          this.email = "";
          this.snackbar = true;
        },
        error => {
          const errors = error.response.data.email.join("; ");
          this.text = "Email address " + errors;
          this.snackbar = true;
        }
      );
    },
    groupSalaries(salaries) {
      const salariesByExperience = _(salaries)
        .groupBy("years_of_experience")
        .map((items, yearsOfExperience) => ({
          yearsOfExperience,
          salaries: _.map(items),
          median: statistics.quantile(_.map(items, "annual_compensation"), 50),
          firstQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            25
          ),
          thirdQuartile: statistics.quantile(
            _.map(items, "annual_compensation"),
            75
          ),
          count: items.length
        }))
        .value();

      return salariesByExperience;
    },
    getFeaturedCompanies() {
      this.loading = "grey";
      const query = {
        exclude_job_roles: ["product-manager"]
      };
      api.getFeaturedCompanies(query).then(resp => {
        this.featuredCompanies = resp.data.items;
      });
    },
    companyPath(company) {
      return {
        name: "showCompany",
        params: { slug: company.slug, locale: this.locale.param }
      };
    },
    rolePath(role) {
      return {
        name: "roleShow",
        params: { slug: role.slug, locale: this.locale.param }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.company-card {
  &:hover {
    background-color: var(--v-gray_lighter-base);
  }
}
</style>
